import { graphql, Link } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../components/content_wrapper/content_wrapper';
import { SEO } from '../components/seo';
import { GatsbyPageProps } from '../gatsby-types';
import Layout from '../layouts/layout';
import { getImgixPath } from '../lib/imgix';
import { RoutePaths } from '../lib/routes';
import { PrismicPartner } from '../models/prismic_partner';
import * as styles from './partners.module.less';

export const query = graphql`
  {
    allPrismicPartner(
      sort: { fields: data___name, order: ASC }
      filter: { uid: { ne: "bass_utopia" } }
    ) {
      nodes {
        data {
          name
          logo {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
        uid
      }
    }
    allPrismicArticle {
      nodes {
        data {
          partner {
            uid
          }
        }
      }
    }
  }
`;

const PartnersPage = (props: GatsbyPageProps) => {
  const partners = props.data.allPrismicPartner.nodes as PrismicPartner[];
  const partnerUidsWithCounts = {} as Record<string, number>;
  props.data.allPrismicArticle.nodes.forEach((node: { data: { partner: { uid: string } } }) => {
    const { uid } = node.data.partner;
    if (uid) {
      partnerUidsWithCounts[uid] = partnerUidsWithCounts[uid] || 0;
      partnerUidsWithCounts[uid] += 1;
    }
  });
  return (
    <Layout>
      <SEO title="Partners" />
      <ContentWrapper>
        <article className={styles.page}>
          <h1>Partners</h1>
          <ul className={styles.partners}>
            {partners.map((partner) => {
              const articleCount = partnerUidsWithCounts[partner.uid] || 0;
              return (
                <li key={partner.uid} className={styles.partner}>
                  <Link to={`${RoutePaths.PARTNERS}${partner.uid}`} className={styles.partnerLink}>
                    {partner.data.logo && (
                      <span className={styles.partnerImg}>
                        <img src={getImgixPath(partner.data.logo.url, { w: 200 })} />
                      </span>
                    )}
                    <span className={styles.partnerName}>
                      {partner.data.name} [{articleCount}]
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default PartnersPage;
